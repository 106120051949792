import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProcesswebService } from './processweb.service';
import { Router } from '@angular/router';
import { ResponseProcess } from '../objects/response';
import { Location, DOCUMENT } from '@angular/common';
import { AccessService } from './access.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessUserService {

  idPerfil: number;
  idmodul: number;
  modulos: any;
  nombre: string;
  url: string;
  rut: any;
  accessIdRol = [];
  contEl: number;
  idrolAcceso: number;
  doblefactor: number;
  headerOptions: any = null;
  doblefactorAccesos = null;

  constructor(
    private _http: HttpClient,
    private processWebService: ProcesswebService,
    private router: Router, private location: Location,
    @Inject(DOCUMENT) document: any,
    private  accessServices : AccessService) {
  }

  public setIdProfile(idPerfil: number) {
    this.idPerfil = idPerfil;
  }

  public setNameProfile(nombre: string) {
    this.nombre = nombre;
  }

  public getIdProfile() {
    return this.idPerfil;                                    
  }

  public getNameProfile() {
    return this.nombre;
  }

  public getIdRol(){
    return this.idrolAcceso
  }

  public getDobleFactor(){
    return this.doblefactor;
  }

  public getDobleFactorAccesos(){
    return this.doblefactorAccesos;
  }

  setUrl(url: string) {
    this.url = url;
  }

  hasAccess(url) {
   // console.log(url)
    if (url != undefined) {
      var rutaParam = url.split("/")[1];
      var rol = this.consultIdRol('/'+rutaParam);      
      this.rut = this.filterAccess(rutaParam);
      
      if(this.rut != null){
        if (this.rut.length === 0) {
          this.router.navigate(['/home'])
        }else{ return rol}
      }
    }
  }

  filterAccess(query) {
    let access = sessionStorage.getItem('accessUser');
    //console.log(access)
    var accessUrl = [];
    var acceso = "/" + query;
    JSON.parse(access).forEach(element => {
      if (element.url != null) {
        accessUrl.push(element.url);
        this.accessIdRol.push(element.idrol);
      } else {
        if(element.menu != null ){
            element.menu.forEach(el => {   
              accessUrl.push(el.url);
              this.accessIdRol.push(element.idrol);
          })          
        }       
      }

    });
    //console.log(accessUrl);
    let accessOrder = accessUrl
    let arrRol =  this.accessIdRol
    var contElment = 0;
    return accessOrder.filter(function (el) {
      if (el != null) {
        contElment++
        //this.consultIdRol(contElment);
        return el.toLowerCase().indexOf(acceso.toLowerCase()) > -1;
      }
    })
  } 

  consultIdRol(query){
    var acceso = query;
    let accesos = JSON.parse(sessionStorage.getItem('accessUser'))

    accesos.forEach(el => {
      var url = (el.url)? '/'+el.url.split("/")[1] : null
      // console.log(url, acceso)
      if(url != null || url === acceso){
        this.idrolAcceso = el.idrol
        // console.log(this.idrolAcceso)
        this.idmodul = el.idmenu;
        this.consultDoubleFactor(this.idmodul)
      } else {
        // console.log('entra al else de consultIdRol en acces-user-sevice')
          var rutaParam = '/'+acceso.split("/")[1];
          if(el.menu != null ){
           el.menu.forEach(element => {
               if(element.url === rutaParam){
                //console.log("entro al set idrol del ménu", element.idrol)
                this.idrolAcceso = element.idrol;
                this.idmodul = element.idmenu;
               }
           });
          }
      }
    })

  }

  consultDoubleFactor(query){

    //console.log("entra a consultar el doblefactor", query)    
    return new Promise( (resp, reject) => {
      const datos =   {
        "datos": {
          'schema': 'plataforma',
          'tabla': 'menus',
          'campo': '*',
          'condicion': 'idmenupadre is null and idmenu=' + query,
          'orden': 'nombre'
          }  
        }  
        this.accessServices.consultGeneric(datos)
        .then( (response: any ) => { 
          //console.log("data" , response)
          var data = JSON.parse(response);
          if( data != null  ){                
            this.doblefactor = data[0].requiereautenticacion; 
            this.doblefactorAccesos = (query = 118) ? data[0].requiereautenticacion : null ;
            resp(this.doblefactor)
            //console.log(this.doblefactor)
          } else {   }      
       })
       .catch(
          error => {          
       })
    })
  } 


  validateDoubleFactor(dataConsult: any){
    
    //console.log(dataConsult)
    return new Promise((resp, reject) => {
      let consulta: any = {
        'datos': {
          ip: '192.168.3.127',
          cuenta: dataConsult.user,
          clave: dataConsult.upass,
          auth: dataConsult.authcode
        }
      };
      this.processWebService.getToken().subscribe(token => {
        let dataresponse = null;
        this.processWebService.execProcess(consulta, '2', token.access_token)        
        .subscribe((res: ResponseProcess) => {
          //console.log(res)           
          if (res.response['idresponse'] === 1 && res.response) {  
            
                this.validateGoogleAuth(dataConsult.authcode)  
                  .subscribe( (data) => {                
                    //dataresponse = data;
                    //console.log(data)
                    //console.log(data.body['status'])
                    //resp(data)
                    let msg = ""
                    switch (data.body['status']) {
                      case 200:                     
                           resp(data);                              
                        break;
                      case 206:
                            msg = data.body['message']
                            reject(data);
                            //console.log(data)
                            //console.log( msg )          
                        break;
                      case 403:
                            msg = data.body['message']
                            
                        break;
                      case 404:
                            msg = data.body['message']
                            
                        break;
                      default:
                        break;
                    }                    
                  })           
                  //console.log(dataresponse)                  
          }          
          else {
            reject(res.response);
          }

        })
        //return dataresponse    
      })
    })
  }


  validateGoogleAuth(authcode){

    //console.log("Entro a validar el google Auth")

    if (authcode) {
      this.headerOptions = new HttpHeaders({
        'x-tfa': authcode
      });
    }    
    const tfasecret = sessionStorage.getItem('userTempCuenta');
    return this._http.post(`${environment.endpointApi}` + 'login-auth', {tfasecret}, {
      observe: 'response',
      headers: this.headerOptions
    });                 

  }

   /*  */


}
