import { Component, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { LoginServiceService } from '../../services/login-service/login-service.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'recovery-password',
  templateUrl: 'recoverypass.component.html',
  styleUrls: ['./recoverypass.component.css'],
})
export class RecoveryPasswordComponent {
  
  @ViewChild('myModalResponse') public myModalResponse: ModalDirective;

  uemail: string = null
  errorMessage: string = null
  alertsDismiss: any = []
  titulo = ''
  mensaje = ''

  passObject = {
    idusuario: ''
  };

  constructor(
    private _loginService: LoginServiceService,
    private _router: Router) {}

  recoveryPass() {    
    this.passObject.idusuario = this.uemail;
    this._loginService.recoveryPassword(this.passObject)
            .then( resp => {              
              const msg = resp as string;
              this.showModal('Información', msg)
            })
            .catch( err => {
              const msg = err.response ? JSON.parse(err.response).nombre : err;
              this.showModal('Información', msg)
            });
  }

  showModal(titulo: string, mensaje: string) {
    this.titulo = titulo;
    this.mensaje = mensaje;
    this.myModalResponse.show();
  }
}
