import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Objetos de consumo
import { RequestProcess } from '../objects/request';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TokenService } from './token.service';
import { environment } from '../../environments/environment';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ProcesswebService {

  tokenConsult: string;
  idusuario = sessionStorage.getItem('idusuario');
  idfranquicia = sessionStorage.getItem('idFranquicia');
  idFranquiciaSelect: number;
  idUserSelect: number
  superUsuario: boolean;
  accessUser: any;
  ip:'';

  constructor(
    private http: HttpClient,
    private token: TokenService) {
      this.consultTypeUser()
      this.accessUser = sessionStorage.getItem('accessUser');
      this.getIp().subscribe((resp: any) => {
        this.ip = resp.ip;
      })
    }

  execProcess(peticion: any, operacion: string, token?: string): Observable<any> {
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('operation', operacion);

    const jsonPeticion = JSON.stringify(peticion);

    /*  const tokenVar =  new Promise( (resp, eject) => {
      this.getToken()
       .subscribe((data: any) =>{
          resp(data.access_token)
      });

     }) */
    // Ejecutamos llamado
    return this.http.post<any>(`${environment.webAdminApi}`, JSON.parse(jsonPeticion), { headers: headers });
    // .pipe(map(  result => result ));
  }

  execProcessEnd(peticion: any, endpoint: string, method: string): Observable<any> {
    // Establecemos cabeceras
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')

    const jsonPeticion = JSON.stringify(peticion);

    /*  const tokenVar =  new Promise( (resp, eject) => {
      this.getToken()
       .subscribe((data: any) =>{
          resp(data.access_token)
      });

     }) */
    // Ejecutamos llamado
    switch(method){
      case 'post':{
        return this.http.post<any>(`${environment.webAdminApi}` + endpoint, JSON.parse(jsonPeticion), { headers: headers });
        break;
      }
      case 'put':{
        return this.http.put<any>(`${environment.webAdminApi}` + endpoint, JSON.parse(jsonPeticion), { headers: headers });
        break;
      }
      default:{
        return this.http.get<any>(`${environment.webAdminApi}` + endpoint, JSON.parse(jsonPeticion));
        break;
      }
    }
    // .pipe(map(  result => result ));
  }


  execProcessInTransactionalDb(request: any, operation: string, token?: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('operation', operation);
    const jsonRequest = JSON.stringify(request);
    return this.http.post<any>(`${environment.endpointTransactional}`, JSON.parse(jsonRequest), { headers: headers });
  }

  sendEmail(peticion: RequestProcess): Observable<any> {
    // Alistamos la peticion
    const jsonPeticion = JSON.stringify(peticion);
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    // Ejecutamos llamado
    return this.http.put<any>(`${environment.webAdminApi}` + '/mail/sendContracts', JSON.parse(jsonPeticion), { headers, });
  }

  testRequest(): Observable<any> {
    return this.http.get<any>('https://api.npms.io/v2/search?q=scope:angular');
  }

  setdataUsuario(idusuario, idfranquicia) {

    sessionStorage.setItem('idusuario', idusuario);
    sessionStorage.setItem('idFranquicia', idfranquicia);

    this.idusuario = idusuario //sessionStorage.getItem('idusuario');
    this.idfranquicia = idfranquicia //sessionStorage.getItem('idFranquicia');
  }

  getToken() {

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
      'Authorization': `Basic ${environment.key_token}`
    })

    const body = new HttpParams()
      .set('username', 'yopresto')
      .set('password', 'Y0pr3st0')
      .set('grant_type', 'password');
    /*
      'grant_type': 'password',
      'username': 'yopresto',
        'password': 'Y0pr3st0',  */
    return this.http.post<any>(`${environment.token}`, body, { headers: headers });

  }

  getLogo(token: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      })
    };

    const body: object = {
      'datos': {
        'idfranquicia': this.idfranquicia
      }
    }
    return this.http.post<any>(`${environment.contenido}`, body, httpOptions);
  }

  /*=============================================================================================================================*/
  /*- MODULOS -*/
  getModulos() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '1'
      })
    };
    const body: object = {
      accion: 1,
      schema: 'plataforma',
      tabla: 'menus',
      campo: '*',
      condicion: 'idmenupadre is null ',
      orden: 'nombre',
      idusuario: this.idusuario

    }
    // const body: object = !this.superUsuario ? {
    //   'datos': {
    //     'schema': 'plataforma',
    //     'tabla': 'menus',
    //     'campo': '*',
    //     'condicion': 'idmenupadre is null',
    //     'orden': 'nombre'
    //   }
    // } : {
    //   'datos': {
    //     'schema': 'plataforma',
    //     'tabla': 'modulos_v as acceso  INNER JOIN admin.usuarios_v as usuario ON acceso.idperfil = usuario.idperfil INNER JOIN plataforma.menus as menu ON menu.idmenu = acceso.idmodulo ',
    //     'campo': 'distinct menu.*',
    //     'condicion': 'usuario.idusuario = ' + this.idusuario
    //   }
    // };
    return this.http.post<any>(`${environment.webAdminApi}`+ '/in-table-admin', body, httpOptions);
  }

  editarModulo(idModule: number, form: object) {
    let url = '';
    if (form['url'] !== null && form['url'] !== "") {
      //console.log(url);
      url = (form['url'].charAt(0) === '/') ? form['url'] : '/' + form['url'];
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '6'
      })
    };
    const body: object = {
      'accion': 2,
      'idmenu': idModule,
      'schema': 'plataforma',
      'tabla': 'menus',
      'campo':
        "nombre='"+ form['modulo'] + "', " +
        "url='"+ url + "', " +
        'estado='+ form['estado']+ ', ' +
        "requiereautenticacion='"+ form['factorAutenticacion'] + "'",
      'condicion': 'idmenu=' + idModule,
      'idusuario': this.idusuario
    };
    return this.http.post<any>(`${environment.webAdminApi}`+ '/in-table-admin', body, httpOptions);
  }

  crearModulo(form: object) {
    console.log(form);
    let url;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '6'
      })
    };

    if (form['url'] !== null && form['url'] !== "") {
      url = (form['url'].charAt(0) === '/') ? form['url'] : '/' + form['url']
    }

    const body: object = {
      // 'datos':
      // {
      // 'accion': 2,
      'idusuario': this.idusuario,
      'ip': '127.0.0.1',
      'id': 0,
      'idmenupadre': '',
      'nombre': form['modulo'],
      'url': url,
      'icono': '',
      'estado': form['estado'],
      'requiereautenticacion': form['factorAutenticacion']
      // }
    };
    console.log(body);
    return this.http.post<any>(`${environment.webAdminApi}`, body, httpOptions);
  }

  getNombreModulo(idModulo: number) {
    console.log('object');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '1'
      })
    };
    const body: object = {
      accion: 1,
      schema: 'plataforma',
      tabla: 'menus',
      campo: 'nombre',
      condicion: `idmenupadre is null and url is null and idmenu = ${idModulo}`,
      orden: 'nombre',
      idusuario: this.idusuario
    };
    return this.http.post<any>(`${environment.webAdminApi}`+ '/in-table-admin', body, httpOptions);

  }

  /*=============================================================================================================================*/

  /*- MENUS -*/
  getMenus() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '1'
      })
    };
    const body: object = {
      accion: 1,
      schema: "plataforma",
      tabla: "menus as a INNER JOIN plataforma.menus as b ON b.idmenupadre = a.idmenu",
      campo: "b.idmenu, b.nombre, b.idmenupadre, b.url,b.idmenupadre, b.requiereautenticacion, b.icono, a.nombre as menupadre, CASE b.estado WHEN 1 THEN 'ACTIVO' WHEN 2 THEN 'INACTIVO' ELSE 'INDEFINIDO' END as estado",

      // 'schema': 'plataforma',
      // 'tabla': 'menus',
      // 'campo': '*',
      // 'condicion': 'idmenupadre is not null and url is not null',
      // 'orden': 'nombre'

    };
    return this.http.post<any>(`${environment.webAdminApi}`+ '/in-table-admin', body, httpOptions);
  }

  editarMenus(idModule: number, form: object) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '6'
      })
    };
    const body: object = {
      idusuario: parseInt(sessionStorage.getItem('idusuario')),
      ip: '127.0.0.1',
      id: idModule,
      idmenupadre: parseInt(form['idModuloPadre']),
      nombre: form['menu'],
      url: (form['url'].charAt(0) === '/') ? form['url'] : '/' + form['url'],
      icono: '',
      estado: form['estado'],
      requiereautenticacion: form['factorAutenticacion']
    };
    return this.http.post<any>(`${environment.webAdminApi}`, body, httpOptions);

  }

  crearMenu(form: object) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '6'
      })
    };
    const body: object = {
      idusuario: parseInt(sessionStorage.getItem('idusuario')),
      ip: '127.0.0.1',
      id: 0,
      idmenupadre: parseInt(form['idModuloPadre']),
      nombre: form['menu'],
      url: (form['url'].charAt(0) === '/') ? form['url'] : '/' + form['url'],
      icono: '',
      estado: form['estado'],
      requiereautenticacion: form['factorAutenticacion']
    };
    // console.log(body);

    return this.http.post<any>(`${environment.webAdminApi}`+ '/menu-manage-admin', body, httpOptions);
  }

  /*=============================================================================================================================*/
  /*- USER -*/
  consultTypeUser() {
    let tipoUsuario = sessionStorage.getItem('idtipouser');

    if (tipoUsuario === '1') {
      this.superUsuario = true;
    } else {
      this.superUsuario = false;
      //this.idfranquicia = sessionStorage.getItem('idFranquicia');
    }
  }

  setIdFranquiciaSelect(idfranquicia: number) {
    this.idFranquiciaSelect = idfranquicia
  }

  getIdFranquiciaSelect() {
    return this.idFranquiciaSelect
  }

  setIdUserSelect(id) {
    this.idUserSelect = id
  }
  getIdUserSelect() {
    return this.idUserSelect
  }

  getUsuariosAdmin(idfranquicia) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body: object = {
      'accion': 1,
      'schema': 'admin',
      'tabla': 'usuarios_v',
      'campo': '*',
      'condicion': 'nombre is not null and idfranquicia=' + idfranquicia+' ',
      'orden': 'nombre',
      'idusuario': this.idusuario
    };
    return this.http.post<any>(`${environment.webAdminApi}` + "/in-table-admin", body, httpOptions);
  }

  getUsuarios(idfranquicia) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '1'
      })
    };

    const body: object = {
      'accion': 1,
      'schema': 'admin',
      'tabla': 'usuarios_v',
      'campo': '*',
      'condicion': 'nombre is not null and idfranquicia=' + idfranquicia + ' ',
      'orden': 'nombre',
      'idusuario': this.idusuario
    };

    return this.http.post<any>(`${environment.webAdminApi}`+ "/in-table-admin", body, httpOptions);
  }


  editarUsuario(idUsuario: any, form: object, UserSelectForm: any) {
    let estadoPersona = 1;
    if (parseInt(form['value']['estado']) === 3) {
      estadoPersona = 2;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '8'
      })
    };

    const body: object = {
      'accion': 2,
      'schema': 'admin',
      'tabla': 'personas',
      'campo':
        'idtipodocumento=' + parseInt(form['value']['tipoDocuemnto']) + ', ' +
        "documento='" + form['value']['numDocumento'] + "', " +
        "nombre='" + form['value']['nombresUsr'] + "', " +
        "apellido='" + form['value']['apellidosUsr'] + "', " +
        "telefono='" + form['value']['telefono'] + "', " +
        "correo='" + form['value']['correo'] + "', " +
        'estado=' + estadoPersona,
      'ip': this.ip,
      'condicion': 'idpersona='+ UserSelectForm['idpersona'],
      'idusuario': this.idusuario
    };

    const body2: object = {
      'accion': 2,
      'schema': 'admin',
      'tabla': 'usuarios',
      'campo': 'idusuario=' + UserSelectForm['idusuario'] + ', ' +
        'idpersona=' + UserSelectForm['idpersona'] + ', ' +
        'idperfil=' + form['value']['perfil'] + ', ' +
        'idestadousuario=' + parseInt(form['value']['estado']) + ', ' +
        'idfranquicia=' + (this.superUsuario ? this.idFranquiciaSelect : this.idfranquicia),
      'ip': this.ip,
      'condicion': 'idusuario='+ UserSelectForm['idusuario'],
      'idusuario': this.idusuario
    }

    // Realizar la primera solicitud y encadenar la segunda
    return this.http.post<any>(`${environment.webAdminApi}/in-table-admin`, body2, httpOptions).pipe( // Actualiza tabla personas
      concatMap(() =>
        this.http.post<any>(`${environment.webAdminApi}/in-table-admin`, body, httpOptions) // Actualiza tabla Usuarios
      )
    );

    // return new Observable(observer => {
    //   this.http.post<any>(`${environment.webAdminApi}/in-table-admin`, body2, httpOptions).subscribe(
    //     response1 => {
    //       this.http.post<any>(`${environment.webAdminApi}/in-table-admin`, body, httpOptions).subscribe(
    //         response2 => {
    //           observer.next(response2); // Emitir el resultado final
    //           observer.complete(); // Completar el Observable
    //         },
    //         error2 => {
    //           observer.error(error2); // Emitir un error si falla la segunda solicitud
    //         }
    //       );
    //     },
    //     error1 => {
    //       observer.error(error1); // Emitir un error si falla la primera solicitud
    //     }
    //   );
    // });

  }

  crearUsuario( form: object) {
    let estadoPersona = 1;
    if (parseInt(form['value']['estado']) === 3) {
      estadoPersona = 2;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '8'
      })
    };
    const body: object = {
      idusuario: this.idusuario, //
      ip: '0.0.0.0',
      id: '0',
      idtipodocumento: parseInt(form['value']['tipoDocuemnto']),
      documento: form['value']['numDocumento'],
      nombre: form['value']['nombresUsr'],
      apellido: form['value']['apellidosUsr'],
      telefono: form['value']['telefono'],
      correo: form['value']['correo'],
      estado: estadoPersona, // ---- EstadoPersona es: 1 Activo y 2 Inactivo
      idperfil: form['value']['perfil'],
      idestadousuario: 2, // --- default cambio contraseña
      idfranquicia: (this.superUsuario) ? this.idFranquiciaSelect : this.idfranquicia,
    };
    return this.http.post<any>(`${environment.webAdminApi}`+ '/register-user-admin', body, httpOptions);
  }


  getFranquicias(peticion: string, operacion: string, token?: string) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'operation': operacion
    })

    const body = new HttpParams()
      .set('username', 'yopresto')
      .set('password', 'Y0pr3st0')
      .set('grant_type', 'password');
    /* 
     'grant_type': 'password',
     'username': 'yopresto',
       'password': 'Y0pr3st0',  */

    return this.http.post<any>(`${environment.endpointTransactional}`, peticion, { headers: headers });
  }
  /*=============================================================================================================================*/
  /* Tipo de documento*/
  getTipoDocumento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'operation': '1'
      })
    };
    const body: object = {
      'accion': 1,
      'schema': 'admin',
      'tabla': 'tipodocumentos',
      'campo': '*',
      'condicion': 'estado = 1',
      'orden': 'nombre',
      'idusuario': this.idusuario
    };
    return this.http.post<any>(`${environment.webAdminApi}`+ "/in-table", body, httpOptions);
  }
  /*=============================================================================================================================*/
  /* Perfiles Usuario*/
  getPerfilUsuario() {
    this.consultTypeUser()
    let idFranquicia = (this.superUsuario) ? this.idFranquiciaSelect : this.idfranquicia

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body: object = {
      'accion': 1,
      'schema': 'plataforma',
      'tabla': 'perfiles',
      'campo': '*',
      'condicion': 'idestadoperfil is not null and idfranquicia=' + idFranquicia + ' ',
      'orden': 'nombre',
      'idusuario': this.idusuario
    };
    return this.http.post<any>(`${environment.webAdminApi}`+ "/in-table-admin", body, httpOptions);
  }


  sendEmailGeneric(peticion: any, url: string): Observable<any> {
    // Alistamos la peticion
    const jsonPeticion = JSON.stringify(peticion);
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    // Ejecutamos llamado
    console.log("------------Enviando Mensaje de recuperacion de clave-----------------------------");
    console.log(url);
    console.log(peticion);
    return this.http.post<any>(`${environment.endpointApi}` + url, JSON.parse(jsonPeticion), { headers, });
  }

  public getIp(): Observable<any> {
    return this.http.get(`${environment.endpoint_ip}`);
  }

  uploadProviderFiles(peticion: any, url: string): Observable<any> {
    return this.http.post<any>(`${environment.endpointApi}${url}`, peticion);
    //return this.http.post<any>(`http://localhost:3000/${url}`, peticion);
  }

  /*=============================================================================================================================*/
  /* Accesos asignados al perfil*/

  setAccess(access: any) {
    this.accessUser = access;
  }

  getAccess() {
    return this.accessUser;
  }

  getContenido(token: any, franquicia: Number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      })
    };

    const body: object = {
      'datos': {
        'idfranquicia': franquicia
      }
    }
    return this.http.post<any>(`${environment.contenido}`, body, httpOptions);
  }

  updateContenido(token: any, idFranquicia: Number, color1: String, color2: String, logo: String, nombre_agencia: String, domain: String, logo_admin: String, mensaje: String, idpadre: String) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      })
    };

    const body: object = {
      'datos': {
        'idfranquicia': idFranquicia,
        'color1': color1,
        'color2': color2,
        'logo': logo,
        'nombre_agencia': nombre_agencia,
        'domain': domain,
        'logo_admin': logo_admin,
        'mensaje': mensaje,
        "idpadre": idpadre
      }
    }
    return this.http.post<any>(`${environment.contenidoUpdate}`, body, httpOptions);
  }

  deleteContenido(token: any, franquicia: Number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      })
    };

    const body: object = {
      'datos': {
        'idfranquicia': franquicia
      }
    }
    return this.http.post<any>(`${environment.contenidoDelete}`, body, httpOptions);
  }

  leypalUploadFile(token: any, request: any) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
    const jsonRequest = JSON.stringify(request);
    return this.http.post<any>(`${environment.ESB_ENDPOINT}${environment.URL_LEYPAL_UPLOAD_FILE}`, JSON.parse(jsonRequest), { headers: headers });
  }

}




