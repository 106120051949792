import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, ReactiveFormsModule, Validators, FormControl } from '@angular/forms';
import { sha512 } from 'js-sha512';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LoginServiceService } from '../../services/login-service/login-service.service';
import { GenericRequestService } from '../../services/generic-request.service';
import { AccessUserService } from '../../services/access-user-service';


@Component({
  selector: 'change-password',
  templateUrl: 'changepass.component.html',
  styleUrls: ['./changepass.component.css'],
})

export class ChangePasswordComponent {
  @ViewChild('myModalResponse') public myModalResponse: ModalDirective;
  @ViewChild('myModalDoubleFactor') public myModalDoubleFactor: ModalDirective;


  doubleFactorForm: FormGroup;
  passNoValid: boolean = false;
  alertsDismiss: any = [];
  mensaje = "";
  userId: number;

  passObject = {
    idusuario: sessionStorage.idusuario,
    pass: '',
    passconfirma: ''
  };
  pass = '';
  passconfirma = ''
  errorMessage: string = null;
  dobleFactor: number;

  userObject: any = {
    user: '',
    upass: ''
  };


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _loginService: LoginServiceService,
    private _router: Router,
    private genericRequestService: GenericRequestService,
    private accesUser: AccessUserService
  ) {

    this.accesUser.hasAccess(this.router.url);
    this.userId = this.genericRequestService.getUserId();
    this.dobleFactor = this.accesUser.getDobleFactor();

  }

  ngOnInit(): void {
    //this.validateIsPerfil()
    this.doubleFactorForm = this.fb.group({
      password: new FormControl('', [Validators.required, Validators.pattern(/[A-Za-z0-9,;._%\-+*/=))&%$#"!)]{5,}$/)]),
      authcode: new FormControl('', [Validators.required]),
    });
  }


  get passwordNoValido() {
    return this.doubleFactorForm.get('password').invalid && this.doubleFactorForm.get('password').touched;
  }
  get authcodeNoValido() {
    return this.doubleFactorForm.get('authcode').invalid && this.doubleFactorForm.get('authcode').touched;
  }


  /* ----Doble factor ----------- */

  doubleFactor() {
    this.userObject.upass = sha512(this.doubleFactorForm.get('password').value);
    this.userObject.user = sessionStorage.getItem('userCuenta');
    this.userObject.authcode = this.doubleFactorForm.get('authcode').value;

    this.accesUser.validateDoubleFactor(this.userObject)
      .then((res: any) => {
        if (res.status === 200) {
          //console.log(res)
          this.changePass()
          //console.log('guardar')             
        }
      })
      .catch(err => {
        console.log(err)
        if (err.body != undefined) {
          this.mensaje = (err.body['message'])
        } else {
          this.mensaje = JSON.parse(err.response).nombre
        }
        this.myModalResponse.show()
      })
  }

  /**
  * Método valida si requiere doble factor y si va a crear o editar
  */

  validateDoubleFactor() {
    if (this.dobleFactor === 0) {
      this.myModalDoubleFactor.show()
    } else {
      this.changePass()
    }
  }

  /* ----End Doble factor--- */

  changePass() {
    if (this.pass === this.passconfirma) {
      if (this.pass == "") {
        this.mensaje = "Debe ingresar una contraseña";
        this.myModalResponse.show();
        return;
      }
      this.passObject.idusuario = this.userId;
      this.passObject.pass = sha512(this.pass);
      this.passObject.passconfirma = sha512(this.passconfirma);

      this._loginService.changePassword(this.passObject).then((data) => {
        this.errorMessage = null;
        const msg = "Cambio de contraseña exitoso";
        this.alertsDismiss.push({
          type: 'success',
          msg,
          timeout: 3000
        });
        setTimeout(() => {
          this._router.navigate(['/home']);
        }, 3000);
      }).catch(err => {
        console.log(err);
        this.passNoValid = true;
        this.mensaje = "Se encontró error al actualizar la clave.";
        this.myModalResponse.show();
      });
    } else {
      this.passNoValid = true;
      this.mensaje = "Las contraseñas no coinciden";
      this.myModalResponse.show();
    }

  }
}
