import {Component, OnInit, OnDestroy} from '@angular/core';
import { navItems, dataUser } from '../../_nav';
import { LoginServiceService } from '../../services/login-service/login-service.service'
import { ProcesswebService } from '../../services/processweb.service';
import { GenericRequestService } from '../../services/generic-request.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})

export class DefaultLayoutComponent implements OnInit{
  public sidebarMinimized = false;
  public navItems = navItems;

  public _dataUSer = dataUser;
  public _navItems: any;
  private serviceObservable:any;
  private logo: any;

  constructor(private LoginServices: LoginServiceService, private _processWebService: ProcesswebService, private genericRequest: GenericRequestService){ 
    this.logo = sessionStorage.getItem('logo')
  }
  
  ngOnInit(): void {
     //this.serviceObservable.unsubscribe(); 
     //this.LoginServices._menu$()
     this.renderMenu();
     this._processWebService.getAccess();
  }

  ngOnDestroy(){     
      this.serviceObservable.unsubscribe();         
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  renderMenu() {

    this.serviceObservable  = this.LoginServices._menu$().subscribe((arg: any) => {
      if (arg.length > 0 ){
        this._navItems = arg.map( itemMenu => {

          return [{
             name: itemMenu.modulo.charAt(0) + itemMenu.modulo.slice(1).toLowerCase(),
             url: (!itemMenu.url && !itemMenu.menu) ? '#' : itemMenu.url, //'/accesos',//
             icon: 'icon-key', // itemMenu.icono,
             badge: {
               varient: 'info'
             },
             children: (itemMenu.menu) ? itemMenu.menu.map( submenu => {
               return [{
                 name: submenu.menu.charAt(0) + submenu.menu.slice(1).toLowerCase(),
                 url:  submenu.url,
                 icon:'icon-puzzle'
               }][0]
             }) : ''
           }][0]
         })
      } 
       else {
        let idperfil = this.genericRequest.getFromSessionStorage('idPerfil');
        this.LoginServices.consultMenus(+idperfil)

        /* let  menus = sessionStorage.getItem('accessUser');
        console.log("entro accesos sin modificar", JSON.parse(menus))
        this._navItems = JSON.parse(menus).map( itemMenu => {

          return [{
             name: itemMenu.modulo.charAt(0) + itemMenu.modulo.slice(1).toLowerCase(),
             url: itemMenu.url, //'/accesos',//
             icon: 'icon-key', // itemMenu.icono,
             badge: {
               varient: 'info'
             },
             children: (itemMenu.menu) ? itemMenu.menu.map( submenu => {            
               return [{
                 name: submenu.menu.charAt(0) + submenu.menu.slice(1).toLowerCase(),
                 url:  submenu.url,
                 icon:'icon-puzzle'
               }][0]
             }) : ''
           }][0]
         }) */
      } 
     //return menu;
    });

  }

  logOut(){
    this.LoginServices.logoutUser()
  }
}
