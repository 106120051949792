import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Import Containers
import {DefaultLayoutComponent} from './containers';


// Guards
import {AuthGuardGuard} from './guards/AuthGuard/auth-guard.guard';
import {ChangePassdwordPreviousComponent} from './views/change-passdword-previous/change-passdword-previous.component';
import {OtpComponent} from './views/otp/otp.component';
import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {RecoveryPasswordComponent} from './views/recovery-pass/recoverypass.component';
import {ChangePasswordComponent} from './views/change-passdword/changepass.component';
import {RegisterComponent} from './views/register/register.component';
import {AuthenticatorComponent} from './views/authenticator/authenticator.component';
import {ProvidermessengerComponent} from './views/provider-messenger/providermessenger.component';
//import { DocumentsVerifyModule } from './views/documents-verify/documents-verify.module';
import { DocumentsVerifyWizardComponent } from './views/documents-verify/documents-verify-wizard/documents-verify-wizard.component';
import { HomeComponent } from './views/home/home.component';

export const routes: Routes = [
  {
    path: 'cambio-clave-anterior',
    component: ChangePassdwordPreviousComponent,
    data: {
      title: 'Cambio clave anterior'
    }
  },
  {
    path: 'otp',
    component: OtpComponent,
    data: {
      title: 'otp'
    }
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    },
    //canActivate: [LoginGuard]
  },
  {
    path: 'recuperacion-cuenta',
    component: RecoveryPasswordComponent,
    data: {
      title: 'Recuperación cuenta'
    }
  },
  {
    path: 'cambio-clave',
    component: ChangePasswordComponent,
    data: {
      title: 'Cambio clave'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  /* {
    path: 'usuarios',
    component: UserManagementComponent,
    data: {
      title: 'Usuarios'
    }
  }, */
  {
    path: 'authenticator',
    component: AuthenticatorComponent,
    data: {
      title: 'Autenticación en dos pasos'
    },
    //canActivate: [AuthGuardGuard]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'accesos',
        loadChildren: () => import('./views/access/access.module').then(m => m.AccessModule)
      },
      {
        path: 'configuracion-productos',
        loadChildren: () => import('./views/product-configuration/product-configuration.module').then(m => m.ProductConfigurationModule)
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./views/user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'menus',
        loadChildren: () => import('./views/menus-management/menus-management.module').then(m => m.MenusManagementModule)
      },
      {
        path: 'modulos',
        loadChildren: () => import('./views/modules-management/modules-management.module').then(m => m.ModulesManagementModule)
      },
      {
        path: 'perfiles',
        loadChildren: () => import('./views/perfils/perfils.module').then(m => m.PerfilsModule)
      },
      {
        path: 'administrator-configuration',
        loadChildren: () =>
          import('./views/admin-configuration/admin-configuration.module').then(m => m.AdminConfigurationModule)
      },
      {
        path: 'franchise-configuration',
        loadChildren: () =>
          import('./views/franchise-configuration/franchise-configuration.module').then(m => m.FranchiseConfigurationModule)
      },
      {
        path: 'documents-verify',
        loadChildren: () =>
          import('./views/documents-verify/documents-verify.module').then(m => m.DocumentsVerifyModule)
      },
      {
        path: 'account-change',
        loadChildren: () =>
          import('./views/account-change/account-change.module').then(m => m.AccountChangeModule)
      },
      {
        path: 'account-opening',
        loadChildren: () =>
          import('./views/account-opening/account-opening.module').then(m => m.AccountOpeningModule)
      },
      {
        path: 'state-change',
        loadChildren: () =>
          import('./views/state-change/state-change.module').then(m => m.StateChangeModule)
      },
      {
        path: 'provider-configuration',
        loadChildren: () =>
          import('./views/provider-configuration/provider-configuration.module').then(m => m.ProviderConfigurationModule)
      },
      {
        path : 'assign-provider',
        loadChildren:() =>
          import('./views/assign-provider/assign-provider.module').then(m => m.AssignProviderModule)
      },
      {
        path : 'product-creation',
        loadChildren:() =>
          import('./views/product-creation/product-creation.module').then(m => m.ProductCreationModule)
      },
      {
        path : 'create-agency',
        loadChildren:() =>
          import('./views/create-agency-configuration/create-agency-configuration.module').then(m => m.CreateAgencyConfigurationModule)
      },
      {
        path: 'provider-messenger',
        loadChildren:() =>
          import('./views/provider-messenger/providermessenger.module').then(m => m.ProvidermessengerModule)
      },
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      },
      {
        path : 'analytic-report',
        loadChildren:() =>
          import('./views/analytic/analytic-configuration.module').then(m => m.AnalyticConfigurationModule)
      },
      {
        path: 'lines-assignment',
        loadChildren: () =>
          import('./views/lines-assignment/lines-assignment.module').then(m => m.LinesAssignmentModule)
      },
      {
        path: 'emails-copy',
        loadChildren: () =>
          import('./views/emails-copy/emails-copy.module').then(m => m.EmailsCopyModule)
      },
      {
        path: 'bulk-upload',
        loadChildren: () =>
          import('./views/bulk-upload/bulk-upload.module').then(m => m.BulkUploadModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'functionality',
        loadChildren: () =>
          import('./views/functionality/functionality.module').then(m => m.FunctionalityModule)
      },
      {
        path: 'configuracion-mensajeria',
        loadChildren: () => import('./views/messages/messages.module').then(m => m.MessagesModule)
      },
      {
        path: 'provider-enrollment',
        loadChildren: () => import('./views/provider-enrollment/provider-enrollment.module').then(m => m.ProviderEnrollmentModule)
      },
      {
        path: 'provider-doc',
        loadChildren: () => import('./views/provider-documentation/provider-documentation.module').then(m => m.ProviderDocumentationModule)
      },
      {
        path: 'unlock-users',
        loadChildren: () =>
          import('./views/unlock-allies/unlock-allies.module').then(m => m.UnlockAlliesModule)
      }
    ]
  },
  {path: '**', component: P404Component}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
